@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

.showBackground {
  top: -92.8125rem;
  height: 28.9375rem;
  left: 0;
  z-index: -5;
  position: relative;
  background-color: rgba(228,234,244,.54);
}

.showBackground>img {
  position: absolute;
}

.showBackground>img:first-child {
  right: 6.9375rem;
  top: -14.5625rem;
  width: 46.4375rem;
  height: 29.6875rem;
}

.heroMetting {
  position: relative;
  margin-top: -58.0625rem;
  z-index: -1;
  height: 31.4375rem;
  background: #e4eaf4;
}

.heroMetting img {
  position: absolute;
  bottom: 0;
}

.heroMetting img:nth-child(1) {
  right: 0;
  height: 27.375rem;
}

.heroMetting img:nth-child(2) {
  left: 0;
  height: 27.375rem;
  top: 0;
  z-index: -1;
}

.welfare {
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  margin-bottom: 5.0625rem;
  width: 75rem;
}

.welfare .introduce {
  font-size: 1.1875rem;
  font-family: PingFangSC-Regular,PingFang SC;
  font-weight: 400;
  color: #000;
  line-height: 1.625rem;
  background: #fff;
  margin-top: 4.875rem;
  height: 25.3125rem;
  position: relative;
  padding-right: 2.1875rem;
  box-shadow: 0 0.125rem 0.25rem 0 #e7e8ea;
  border: 0.0625rem solid #e5e8ee;
}

.welfare .introduce b {
  font-size: 1.625rem;
  font-family: PingFangSC-Medium,PingFang SC;
  font-weight: 500;
  color: #000;
  line-height: 2.3125rem;
}

.welfare .introduce img {
  float: left;
  margin-right: 2.1875rem;
  height: 100%;
}

.welfare .introduce .image02 {
  bottom: -3.4375rem;
  left: -17.0625rem;
  width: 5.9375rem;
  height: 6.25rem;
}

.welfare .containSalary {
  padding: 4.5rem 0 4.0625rem;
  margin-top: 4.375rem;
}

.welfare .image01,
.welfare .image02 {
  position: absolute;
  animation: rotate 12s linear infinite;
}

.welfare dl {
  margin-top: 10rem;
  position: relative;
}

.welfare dl .image01 {
  top: 6.1875rem;
  right: -14.875rem;
  width: 7.0625rem;
  height: 7.1875rem;
}

.welfare dt {
  font-size: 1.625rem;
  font-family: PingFangSC-Medium,PingFang SC;
  font-weight: 500;
  line-height: 2.3125rem;
  color: #353535;
  text-align: center;
  margin-bottom: 2.3125rem;
}

.welfare ul.salary {
  display: flex;
  justify-content: space-between;
}

.welfare ul.salary li {
  background: #fff;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(0,0,0,.08);
  display: inline-block;
  height: 15.75rem;
  padding: 2.75rem 2.125rem 1.625rem;
  vertical-align: middle;
  width: calc(25% - 2.9375rem);
  margin: 0 1.4375rem;
  box-sizing: border-box;
}

.welfare ul.salary li:first-child {
  margin-left: 0;
}

.welfare ul.salary li:last-child {
  margin-right: 0;
}

.welfare ul.salary li>span {
  height: 2.0625rem;
  font-size: 1.5rem;
  font-family: PingFangSC-Regular,PingFang SC;
  font-weight: 400;
  color: #454545;
  line-height: 2.0625rem;
}

.welfare ul.salary li>span img {
  margin-right: 1.0625rem;
  width: 1.625rem;
  height: 1.625rem;
  vertical-align: sub;
}

.welfare ul.salary li>p {
  font-size: 1rem;
  font-family: PingFangSC-Regular,PingFang SC;
  font-weight: 400;
  color: #666;
  line-height: 1.75rem;
  margin-top: 1rem;
}

.welfare dd {
  font-size: 1.125rem;
  font-family: PingFangSC-Regular,PingFang SC;
  font-weight: 400;
  color: #000;
  line-height: 1.5625rem;
}

.welfare .position {
  margin-top: 1.6875rem;
}

.welfare .position a {
  display: inline-block;
  text-align: center;
  background: #2e98ef;
  padding: 0.5625rem 1.5rem;
  width: 6.75rem;
  height: 1.5625rem;
  font-size: 1.125rem;
  font-family: PingFangSC-Regular,PingFang SC;
  font-weight: 400;
  color: #fff;
  line-height: 1.5625rem;
}

@media screen and (max-width: 1845px) {
  .welfare dl .image01 {
    display: none;
  }
}