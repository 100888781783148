@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

body,
ol,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}

body {
  overflow-x: auto;
  min-width: 75rem;
  margin: 0 auto;
  font: 400 1em/1.8 "PingFang SC","Microsoft Yahei","Avenir","Segoe UI","Hiragino Sans GB",STHeiti,"Microsoft Sans Serif","WenQuanYi Micro Hei",sans-serif;
  background: #fff;
}

ol,
ul {
  list-style: none;
}

a {
  color: #353535;
  text-decoration: none;
}

img {
  max-width: 100%;
}

::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.375rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.625rem;
  -webkit-box-shadow: inset 0 0 0.3125rem rgba(0,0,0,.2);
  background: rgba(0,0,0,.2);
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.3125rem rgba(0,0,0,.2);
  border-radius: 0;
  background: rgba(0,0,0,.1);
}