@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

.nav {
  padding: 1.75rem 0;
  display: flex;
  justify-content: space-evenly;
}

.nav .selected {
  color: #353535;
  border-bottom: 0.125rem solid #000;
}

.nav .active {
  border-bottom: 0.125rem solid transparent;
}

.nav a {
  display: inline-block;
  line-height: 3.3125rem;
  font-size: 1.5rem;
  font-weight: 400;
  padding: 0.625rem 0;
  padding-bottom: 0;
  min-width: 7.75rem;
  text-align: center;
}

.nav a img {
  vertical-align: bottom;
}