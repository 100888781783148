footer {
  color: #fff;
  overflow: hidden;
  padding: 2.75rem 0 1.625rem;
  background: #15191f;
}

footer .info {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  margin: 0 auto;
  width: 75rem;
}

footer .address {
  overflow: hidden;
  width: 53.125rem;
}

footer .address ul {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  opacity: .6;
}

footer .address span {
  font-weight: normal;
  font-size: 1.25rem;
  vertical-align: middle;
  margin-left: 0.625rem;
  opacity: .8;
}

footer .contact li {
  width: 50%;
  display: inline-block;
  margin-bottom: 3.125rem;
}

footer .contact li img {
  width: 2.4375rem;
  height: 2.4375rem;
}

footer .contact li .footerText {
  margin-left: 3rem;
  margin-top: -3.375rem;
}

footer .contact li .footerText span {
  font-size: 1.25rem;
  vertical-align: middle;
  opacity: .8;
}

footer .contact li .footerText p {
  font-size: 0.875rem;
  margin-top: -0.3125rem;
  opacity: .6;
}

footer .copyright {
  text-align: center;
  font-size: 0.75rem;
  margin: 0 18.75%;
  margin-top: 2.125rem;
}

footer .copyright img {
  vertical-align: sub;
  margin: 0 0.3125rem;
  width: 0.9375rem;
  height: 0.9375rem;
}