.item1 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 75rem;
  margin: 8.8125rem auto 5.3125rem auto;
}

.item1>img {
  width: 33.3125rem;
}

.item1 .right {
  margin-left: 6.5rem;
  position: relative;
}

.item1 .right>img {
  left: -3.5625rem;
  top: -8.125rem;
  position: absolute;
  animation: rotate 12s linear infinite;
}

.item1 strong {
  display: block;
  font-size: 1.5rem;
  font-weight: 400;
  color: #000;
  margin: 6.0625rem 0 1rem;
}

.item1 p {
  font-size: 1.125rem;
  font-weight: 300;
  color: #585757;
  line-height: 1.5625rem;
}

.item2 {
  width: 75rem;
  margin: 0 auto 4.625rem auto;
}

.item2 .top {
  display: flex;
}

.item2 .top strong {
  display: block;
  font-size: 1.5rem;
  font-weight: 400;
  color: #000;
  line-height: 2.6875rem;
}

.item2 .top strong span {
  cursor: pointer;
  font-size: 1.125rem;
  font-weight: 300;
  color: #2e98ef;
  line-height: 2.6875rem;
  float: right;
}

.item2 .top strong span img {
  margin-left: 0.9375rem;
}

.item2 .top .text {
  display: flex;
  margin-top: -1.25rem;
  width: 100%;
}

.item2 .top .text ul {
  min-width: 10.125rem;
  padding-left: 1.4375rem;
  margin-top: -0.4375rem;
}

.item2 .top .text ul:last-child {
  margin-left: 1.875rem;
}

.item2 .top .text li {
  list-style: square;
  font-size: 1.125rem;
  font-weight: 400;
  color: #000;
  line-height: 4.375rem;
  margin-right: 0;
}

.item2 .top .text li>p {
  margin-left: 0;
  font-size: 0.875rem;
  font-weight: 300;
  color: #666;
  line-height: 1.25rem;
  min-width: 100%;
  margin-top: -1.0625rem;
}

.item2 .top .text p {
  min-width: 29.375rem;
  margin: 0 3.125rem;
  font-size: 1.75rem;
  font-weight: 400;
  color: #585757;
  line-height: 3.125rem;
}

.item2 .top .containItem {
  width: calc(100% - 34.375rem);
  margin-right: 3.125rem;
}

.item2 .top .containImage {
  min-width: 29.375rem;
  position: relative;
}

.item2 .top .containImage>img:first-child {
  position: absolute;
  right: -18.75rem;
  top: -1.375rem;
  animation: rotate 12s linear infinite;
}

.item2 .top .containImage>img:last-child {
  position: absolute;
  right: calc((75rem - 100vw) / 2 + 0.1875rem);
  bottom: -4.1875rem;
}

.item2 .bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 4.625rem;
}

.item2 .bottom .containImage {
  min-width: 29.5625rem;
  max-width: 35rem;
  margin-right: 4%;
  position: relative;
}

.item2 .bottom .containImage>img:first-child {
  position: absolute;
  left: -18.5rem;
  top: -5.0625rem;
  animation: rotate 12s linear infinite;
}

.item2 .bottom .containImage>img:nth-child(2) {
  position: absolute;
  left: 5.1875rem;
  bottom: -7.4375rem;
  animation: rotate 12s linear infinite;
}

.item2 .bottom strong {
  display: block;
  font-size: 1.5rem;
  font-weight: 400;
  color: #000;
}

.item2 .bottom strong span {
  cursor: pointer;
  font-size: 1.125rem;
  font-weight: 300;
  color: #2e98ef;
  line-height: 2.6875rem;
  float: right;
}

.item2 .bottom strong span img {
  margin-left: 0.9375rem;
}

.item2 .bottom .list {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.item2 .bottom .list ul {
  padding-left: 1.4375rem;
  margin-top: -0.4375rem;
  margin-right: 1.1875rem;
  margin-left: 1.1875rem;
}

.item2 .bottom .list ul:first-child {
  margin-left: 0;
}

.item2 .bottom .list ul:last-child {
  margin-right: 0;
}

.item2 .bottom .list li {
  list-style: square;
  font-size: 1.125rem;
  font-weight: 400;
  color: #000;
  line-height: 4.375rem;
}

.item2 .bottom .list li>p {
  margin-left: 0;
  font-size: 0.875rem;
  font-weight: 300;
  color: #666;
  line-height: 1.25rem;
  min-width: 100%;
  margin-top: -1rem;
}

.operationOuter {
  width: 100%;
  background: #f4f7fc;
}

.operationOuter .cooperation {
  padding: 2.5625rem 0 4.3125rem;
  margin: 0 auto;
  width: 75rem;
}

.operationOuter .cooperation strong {
  display: block;
  text-align: center;
  font-weight: normal;
  font-size: 1.5rem;
  font-weight: 400;
  color: #000;
  line-height: 5.0625rem;
}

.operationOuter .cooperation ul {
  display: flex;
  justify-content: space-between;
}

.operationOuter .cooperation ul li {
  width: 11.75rem;
  height: 5.125rem;
  background-color: #fff;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(21,25,31,.1);
}

.operationOuter .cooperation ul li img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1845px) {
  .item2 .top .containImage>img:first-child {
    display: none;
  }
}

@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}