@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

.about {
  margin: 0 auto;
  margin-top: 5.125rem;
  width: 75rem;
}

.content {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.content .image03 {
  position: absolute;
  left: -18.6875rem;
  top: -4.5rem;
}

.content .image01,
.content .image02 {
  position: absolute;
  animation: rotate 12s linear infinite;
}

.content .image01 {
  bottom: 0.3125rem;
  right: -14.875rem;
  width: 7.0625rem;
  height: 7.1875rem;
}

.content .image02 {
  top: 3.9375rem;
  left: -16.9375rem;
  width: 5.9375rem;
  height: 6.25rem;
}

.content .left,
.content .right {
  width: 49%;
  padding: 0 0 6.8125rem;
}

.content .left {
  margin-left: 1.75rem;
}

.content .left strong {
  display: block;
  font-size: 1.5rem;
  font-weight: 300;
  color: #000;
  line-height: 2.0625rem;
  margin-bottom: 1rem;
  margin-top: 5.875rem;
}

.content .left p {
  font-size: 1.125rem;
  font-weight: 300;
  color: #666;
  line-height: 1.5625rem;
}

.content .left dl {
  font-weight: 400;
}

.content .left dt {
  font-size: 2.25rem;
  line-height: 3.4375rem;
  color: #353535;
}

.content .left dd {
  font-size: 1.75rem;
  color: #6f6e6e;
  line-height: 3rem;
  margin-top: 2.8125rem;
}

.content .right dl {
  font-weight: 400;
}

.content .right dt {
  font-size: 2.25rem;
  line-height: 3.4375rem;
  color: #353535;
}

.content .right dd {
  font-size: 1.75rem;
  color: #6f6e6e;
  line-height: 3rem;
  margin-top: 2.8125rem;
}

.verification {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.verification .logo {
  font-size: 1.25rem;
  font-family: PingFangSC-Regular,PingFang SC;
  font-weight: 400;
  color: #000;
  line-height: 1.75rem;
}

.verification .logo>img {
  display: block;
  margin: 0 auto;
}

.verification .logo:last-child {
  padding: 3.25rem 5.8125rem 0 0;
}

.verification .logo:last-child img {
  margin-bottom: 0.6875rem;
}

.verification .logo:first-child {
  padding: 0 2.75rem 0 4.0625rem;
  text-align: center;
}

.verification .logo:first-child img {
  margin-bottom: 1.4375rem;
}

@media screen and (max-width: 1845px) {
  .content .image01 {
    display: none;
  }
}