@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

.banner {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  text-align: center;
  width: 100%;
}

.banner .desc {
  position: absolute;
  font-size: 1.125rem;
  font-weight: 300;
  top: 63%;
  left: calc((100vw - 75rem) / 2);
  color: #fff;
  width: 75rem;
}

.banner .list {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.banner .list li {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.banner .list a {
  display: block;
}

.banner .list img {
  opacity: 0;
  transition: opacity 2s;
}

.banner .list .active {
  opacity: 1;
}

.banner .point {
  width: 5.625rem;
  position: absolute;
  left: 50%;
  bottom: 2.1875rem;
  z-index: 20;
  display: flex;
  justify-content: space-between;
  transform: translateX(-50%);
}

.banner .point li {
  cursor: pointer;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: rgba(255,255,255,.5);
}

.banner .point .selectd {
  background-color: #fff;
}

.banner img {
  vertical-align: middle;
}