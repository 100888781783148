@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

.line {
  width: 100%;
  height: 0.0625rem;
  background-color: #c5c5c5;
  bottom: -7.375rem;
  position: relative;
}

.tab {
  margin: 0 auto;
  width: 75rem;
  position: relative;
}

.tab .blank {
  position: absolute;
  left: calc((75rem - 100vw) / 2);
  top: 37.375rem;
  width: 99.9vw;
  height: 50rem;
  background: rgba(228,234,244,.54);
  z-index: -1;
}

.tab .nav {
  display: flex;
  justify-content: space-between;
  margin-top: 3.4375rem;
  padding: 1.6875rem 0;
}

.tab .nav li {
  font-size: 1.125rem;
  font-weight: 400;
  color: #353535;
  line-height: 1.5625rem;
  cursor: pointer;
  text-align: center;
  padding-bottom: 0.5625rem;
}

.tab .nav li svg {
  margin-right: 0.4375rem;
}

.tab .nav .selectd {
  color: #2e98ef;
  font-weight: 500;
  border-bottom: 0.125rem solid #2e98ef;
}

.tab .nav .selectd svg path {
  fill: #2e98ef;
}

.tab .content {
  padding: 3rem 4.75rem;
  padding-bottom: 7rem;
  background: url("../common/svg/product_bg.svg") right 3.125rem no-repeat,url("../common/svg/creator_bg.svg") center 0 no-repeat;
}

.tab .content .item {
  display: flex;
  align-items: flex-start;
  position: relative;
}

.tab .content .item .image01,
.tab .content .item .image02 {
  position: absolute;
  animation: rotate 12s linear infinite;
}

.tab .content .item .image01 {
  bottom: 5.9375rem;
  right: -15.5625rem;
  width: 7.0625rem;
  height: 7.1875rem;
}

.tab .content .item .image02 {
  top: 13.3125rem;
  left: -21.1875rem;
  width: 5.9375rem;
  height: 6.25rem;
}

.tab .content .item .right,
.tab .content .item .right1,
.tab .content .item .right2,
.tab .content .item .right3 {
  background: url("../common/svg/creator_bg.svg") center 30rem no-repeat;
  overflow: auto;
  height: 100%;
}

.tab .content .item .right ul.containUl li,
.tab .content .item .right ul.containThreeUl li,
.tab .content .item .right1 ul.containUl li,
.tab .content .item .right1 ul.containThreeUl li,
.tab .content .item .right2 ul.containUl li,
.tab .content .item .right2 ul.containThreeUl li,
.tab .content .item .right3 ul.containUl li,
.tab .content .item .right3 ul.containThreeUl li {
  text-align: center;
  float: left;
}

.tab .content .item .right ul.containUl li>p,
.tab .content .item .right ul.containThreeUl li>p,
.tab .content .item .right1 ul.containUl li>p,
.tab .content .item .right1 ul.containThreeUl li>p,
.tab .content .item .right2 ul.containUl li>p,
.tab .content .item .right2 ul.containThreeUl li>p,
.tab .content .item .right3 ul.containUl li>p,
.tab .content .item .right3 ul.containThreeUl li>p {
  text-align: left;
}

.tab .content .item .right ul.containUl li,
.tab .content .item .right1 ul.containUl li,
.tab .content .item .right2 ul.containUl li,
.tab .content .item .right3 ul.containUl li {
  width: calc(50% - 3.125rem);
  margin-right: 3.125rem;
  margin-left: 3.125rem;
}

.tab .content .item .right ul.containUl li:nth-child(2n),
.tab .content .item .right1 ul.containUl li:nth-child(2n),
.tab .content .item .right2 ul.containUl li:nth-child(2n),
.tab .content .item .right3 ul.containUl li:nth-child(2n) {
  margin-right: 0;
}

.tab .content .item .right ul.containUl li:nth-child(2n+1),
.tab .content .item .right ul.containUl li:nth-of-type(1),
.tab .content .item .right1 ul.containUl li:nth-child(2n+1),
.tab .content .item .right1 ul.containUl li:nth-of-type(1),
.tab .content .item .right2 ul.containUl li:nth-child(2n+1),
.tab .content .item .right2 ul.containUl li:nth-of-type(1),
.tab .content .item .right3 ul.containUl li:nth-child(2n+1),
.tab .content .item .right3 ul.containUl li:nth-of-type(1) {
  margin-left: 0;
}

.tab .content .item .right ul.containThreeUl li,
.tab .content .item .right1 ul.containThreeUl li,
.tab .content .item .right2 ul.containThreeUl li,
.tab .content .item .right3 ul.containThreeUl li {
  width: calc(33.33% - 4.6875rem);
  margin-right: 2.34375rem;
  margin-left: 2.34375rem;
}

.tab .content .item .right ul.containThreeUl li:nth-child(3n),
.tab .content .item .right1 ul.containThreeUl li:nth-child(3n),
.tab .content .item .right2 ul.containThreeUl li:nth-child(3n),
.tab .content .item .right3 ul.containThreeUl li:nth-child(3n) {
  margin-right: 0;
  width: calc(33.33% - 2.34375rem);
}

.tab .content .item .right ul.containThreeUl li:nth-child(3n+1),
.tab .content .item .right ul.containThreeUl li:nth-of-type(1),
.tab .content .item .right1 ul.containThreeUl li:nth-child(3n+1),
.tab .content .item .right1 ul.containThreeUl li:nth-of-type(1),
.tab .content .item .right2 ul.containThreeUl li:nth-child(3n+1),
.tab .content .item .right2 ul.containThreeUl li:nth-of-type(1),
.tab .content .item .right3 ul.containThreeUl li:nth-child(3n+1),
.tab .content .item .right3 ul.containThreeUl li:nth-of-type(1) {
  margin-left: 0;
  width: calc(33.33% - 2.34375rem);
}

.tab .content .item .right1 {
  background-position: center 30.625rem;
}

.tab .content .item .right2 {
  background-position: center 30.625rem;
}

.tab .content .item .right3 {
  background-position: center 30.625rem;
}

.tab .content .item ul {
  margin-top: 4.375rem;
  overflow: hidden;
}

.tab .content .item ul li {
  font-size: 1.25rem;
  font-weight: 400;
  color: #000;
  line-height: 1.75rem;
  height: 9.6875rem;
}

.tab .content .item ul li p {
  font-size: 0.875rem;
  margin-top: 0.3125rem;
  line-height: 1.25rem;
  color: #666;
  margin-bottom: 3.4375rem;
}

.tab .content .item ul span b {
  margin-right: 0.9375rem;
  width: 0.5rem;
  height: 0.5rem;
  display: inline-block;
  background: #000;
}

.tab .content .item ul.newNav {
  display: flex;
  justify-content: space-between;
  margin-top: 3.4375rem;
  padding: 0 3.3125rem;
  border-bottom: 0.0625rem solid #e5e5e5;
}

.tab .content .item ul.newNav li {
  font-size: 1.125rem;
  font-weight: 400;
  color: #353535;
  line-height: 1.5625rem;
  height: 1.5625rem;
  cursor: pointer;
  text-align: center;
  padding-bottom: 0.6875rem;
}

.tab .content .item ul.newNav li svg {
  margin-right: 0.6875rem;
}

.tab .content .item ul.newNav .selectd {
  color: #2e98ef;
  background: url("../common/svg/scene-border.png") center 1.9375rem no-repeat;
}

.tab .content .item .containScene p {
  margin: 1.4375rem 3.25rem;
  font-size: 0.875rem;
  font-weight: 300;
  color: #666;
  line-height: 1.25rem;
  text-align: left;
  min-height: 3.75rem;
}

.tab .content .item .containScene>img {
  background: #cee3ff;
  padding: 1.25rem;
  width: calc(100% - 2.5rem);
}

.tab .content .item strong {
  display: block;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 400;
  color: #353535;
}

.tab .content .item strong img {
  margin: 0 1.25rem;
  vertical-align: middle;
}

.tab .content .item p {
  font-size: 1.625rem;
  font-weight: 400;
  color: #353535;
  line-height: 3.125rem;
  margin: 4.3125rem 0 7.125rem;
  text-align: center;
}

@media screen and (max-width: 1845px) {
  .tab .content .item .image01 {
    display: none;
  }
}