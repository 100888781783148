@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

.line {
  width: 100%;
  height: 0.0625rem;
  background-color: #c5c5c5;
  bottom: -8.0625rem;
  position: relative;
}

.tab {
  margin: 0 auto;
  margin-bottom: 7.625rem;
  width: 75rem;
  position: relative;
}

.tab>img {
  position: absolute;
  left: -15rem;
  bottom: -7.625rem;
  width: 46.4375rem;
  height: 29.6875rem;
}

.tab .nav {
  display: flex;
  justify-content: space-between;
  margin-top: 3.4375rem;
  padding: 1.6875rem 0;
}

.tab .nav li {
  width: 6rem;
  padding: 0 0.625rem;
  font-size: 1.5rem;
  font-weight: 400;
  color: #353535;
  line-height: 2.0625rem;
  cursor: pointer;
  text-align: center;
}

.tab .nav .selectd {
  color: #2e98ef;
  border-bottom: 0.1875rem solid #2e98ef;
  padding-bottom: 0.6875rem;
}

.tab .content {
  background: url("../common/svg/product_bg.svg") right 15.625rem no-repeat,url("../common/svg/creator_bg.svg") center 1.25rem no-repeat;
  padding: 3rem 2.1875rem;
  padding-bottom: 0;
}

.tab .content .item {
  display: flex;
  align-items: flex-start;
  position: relative;
}

.tab .content .item .image01,
.tab .content .item .image02 {
  position: absolute;
  animation: rotate 12s linear infinite;
}

.tab .content .item .image01 {
  bottom: 5.875rem;
  right: -15.75rem;
  width: 7.0625rem;
  height: 7.1875rem;
}

.tab .content .item .image02 {
  top: 13.3125rem;
  left: -19.4375rem;
  width: 5.9375rem;
  height: 6.25rem;
}

.tab .content .item .right,
.tab .content .item .right1 {
  background: url("../common/svg/creator_bg.svg") center 38.4375rem no-repeat,url("../common/svg/creator_bg.svg") center 20rem no-repeat;
  overflow: auto;
  height: 100%;
}

.tab .content .item .right ul.containUl li,
.tab .content .item .right ul.containThreeUl li,
.tab .content .item .right ul.containFourUl li,
.tab .content .item .right1 ul.containUl li,
.tab .content .item .right1 ul.containThreeUl li,
.tab .content .item .right1 ul.containFourUl li {
  text-align: center;
  float: left;
}

.tab .content .item .right ul.containUl li>span,
.tab .content .item .right ul.containThreeUl li>span,
.tab .content .item .right ul.containFourUl li>span,
.tab .content .item .right1 ul.containUl li>span,
.tab .content .item .right1 ul.containThreeUl li>span,
.tab .content .item .right1 ul.containFourUl li>span {
  font-weight: 500;
  color: #000;
  font-family: PingFangSC-Medium,PingFang SC;
}

.tab .content .item .right ul.containUl li>p,
.tab .content .item .right ul.containThreeUl li>p,
.tab .content .item .right ul.containFourUl li>p,
.tab .content .item .right1 ul.containUl li>p,
.tab .content .item .right1 ul.containThreeUl li>p,
.tab .content .item .right1 ul.containFourUl li>p {
  text-align: left;
}

.tab .content .item .right ul.containUl li,
.tab .content .item .right1 ul.containUl li {
  width: calc(50% - 3.125rem);
  margin-right: 3.125rem;
  margin-left: 3.125rem;
}

.tab .content .item .right ul.containUl li:nth-child(2n),
.tab .content .item .right1 ul.containUl li:nth-child(2n) {
  margin-right: 0;
}

.tab .content .item .right ul.containUl li:nth-child(2n+1),
.tab .content .item .right ul.containUl li:nth-of-type(1),
.tab .content .item .right1 ul.containUl li:nth-child(2n+1),
.tab .content .item .right1 ul.containUl li:nth-of-type(1) {
  margin-left: 0;
}

.tab .content .item .right ul.containFourUl li,
.tab .content .item .right1 ul.containFourUl li {
  width: calc(25% - 3.625rem);
  margin-right: 1.8125rem;
  margin-left: 1.8125rem;
  font-size: 1rem;
}

.tab .content .item .right ul.containFourUl li img,
.tab .content .item .right1 ul.containFourUl li img {
  display: block;
  margin: 0 auto;
  margin-bottom: 1.375rem;
}

.tab .content .item .right ul.containFourUl li:nth-child(4n),
.tab .content .item .right1 ul.containFourUl li:nth-child(4n) {
  margin-right: 0;
}

.tab .content .item .right ul.containFourUl li:nth-child(4n+1),
.tab .content .item .right ul.containFourUl li:nth-of-type(1),
.tab .content .item .right1 ul.containFourUl li:nth-child(4n+1),
.tab .content .item .right1 ul.containFourUl li:nth-of-type(1) {
  margin-left: 0;
}

.tab .content .item .right ul.containOneUl p,
.tab .content .item .right1 ul.containOneUl p {
  font-size: 0.875rem;
  line-height: 1.25rem;
  display: table;
}

.tab .content .item .right ul.containOneUl span b,
.tab .content .item .right1 ul.containOneUl span b {
  margin-right: 0.9375rem;
  width: 0.5rem;
  height: 0.5rem;
  display: inline-block;
  background: #000;
}

.tab .content .item .right ul.containThreeUl li,
.tab .content .item .right1 ul.containThreeUl li {
  width: calc(33.33% - 6.25rem);
  margin-right: 3.125rem;
  margin-left: 3.125rem;
}

.tab .content .item .right ul.containThreeUl li>img,
.tab .content .item .right1 ul.containThreeUl li>img {
  display: block;
  margin: 0 auto;
  margin-bottom: 1.375rem;
}

.tab .content .item .right ul.containThreeUl li:nth-child(3n),
.tab .content .item .right1 ul.containThreeUl li:nth-child(3n) {
  margin-right: 0;
}

.tab .content .item .right ul.containThreeUl li:nth-child(3n+1),
.tab .content .item .right ul.containThreeUl li:nth-of-type(1),
.tab .content .item .right1 ul.containThreeUl li:nth-child(3n+1),
.tab .content .item .right1 ul.containThreeUl li:nth-of-type(1) {
  margin-left: 0;
}

.tab .content .item .right1 {
  background: url("../common/svg/creator_bg.svg") center 34.0625rem no-repeat,url("../common/svg/creator_bg.svg") center 16.25rem no-repeat;
}

.tab .content .item ul {
  margin-top: 1.25rem;
  overflow: auto;
}

.tab .content .item ul:nth-of-type(1) {
  display: flex;
  justify-content: space-between;
}

.tab .content .item ul li {
  font-size: 1.125rem;
  font-weight: 400;
  color: #353535;
  line-height: 1.75rem;
  min-height: 18.375rem;
}

.tab .content .item ul li>img {
  margin-right: 0.9375rem;
}

.tab .content .item ul li p {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-top: 0.3125rem;
  margin-bottom: 0;
}

.tab .content .item strong {
  display: block;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 500;
  color: #353535;
}

.tab .content .item strong img {
  margin: 0 1.25rem;
  vertical-align: middle;
}

.tab .content .item p {
  font-size: 1.625rem;
  font-weight: 400;
  color: #353535;
  line-height: 3.125rem;
  margin: 4.3125rem 0 7.125rem;
}

@media screen and (max-width: 1845px) {
  .tab .content .item .image01 {
    display: none;
  }
}